import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { PreloadAllModules } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'index'  },
  { path: '/', redirectTo: 'index'},
  
  // {path: 'member', component: MemberComponent },
  { path: 'practice', loadChildren: () => import('./practice/practice.module').then(m => m.PracticeModule)  },
  { path: 'mine', loadChildren: () => import('./mine/mine.module').then(m => m.MineModule)  },
  { path: 'join', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'mock', loadChildren: () => import('./mock/mock.module').then(m => m.MockModule) },
  { path: 'index', loadChildren: () => import('./index/index.module').then(m => m.IndexModule) },
  
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'learn/:type', loadChildren: () => import('./learn/learn.module').then(m => m.LearnModule) },
  { path: 'plan', loadChildren: () => import('./plan/plan.module').then(m => m.PlanModule) },
  { path: 'main', loadChildren: () => import('./gre/gre.module').then(m => m.GreModule) },
  { path: 'course', loadChildren: () => import('./course/course.module').then(m => m.CourseModule)},
  { path: 'resource', loadChildren: () => import('./resources/resources.module').then(m => m.ResourcesModule)},
  { path: '**', redirectTo: 'index'  },
];

  const routerOptions: ExtraOptions = {
    useHash: false,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy',
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: "reload",
    scrollOffset: [0,120],
  };

@NgModule({
  imports: [RouterModule.forRoot(routes,routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
