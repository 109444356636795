import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Subject,ReplaySubject } from 'rxjs';
import { DatePipe, formatDate } from '@angular/common';
import * as Parse from 'parse';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AlertService } from './alert.service';
import { UserModel } from '../_models/user.model';
import { UsernameAuthProvider } from 'tcb-js-sdk/dist/auth/usernameAuthProvider';
import { Router } from '@angular/router';
import { RouteService } from './gre/route.service';
import { ControlService } from './gre/control.service';
import { LocalMemory } from './gre/state.service';
import { Order } from '../_models/gre/order.model';
import { UserPromotionService } from 'src/app/_services/gre/user.promote.service';
import { exitCode } from 'process';
import { UserInfo } from 'os';


@Injectable({
  providedIn: 'root'
})
export class UserService {
UserSubject = new ReplaySubject<Parse.User>(1);
PermissionSubject = new ReplaySubject<Order>(1);

code = Parse.Object.extend("smsCode");
codeObject= new this.code();

constructor( private http: HttpClient,
  private alertService:AlertService,
  private router:Router,
  private promotionService:UserPromotionService,
  private controlService:ControlService,
  @Inject(LOCALE_ID) private locale: string,
  private routeService:RouteService){

}
async login(username, password){
    const user =await Parse.User.logIn(username, password);
    this.emitUser();
}

getCount():Promise<number>{
  const userQuery = new Parse.Query(Parse.User);
  return userQuery.count().then(re=>{
    return re;
  })
}

async mobile_login(phone, unionId=undefined,displayName=undefined,avartar=undefined){

    const users= await this.checkExist(phone, unionId);
  

    if(users.length>0){
      let user = users[0];
     
     
      let pass = undefined;
      let smsCode = user.get('smsCode');
      if(smsCode!=undefined)
      { 
        const query = new Parse.Query(this.codeObject);
        let userName=user.get('username')
        if(unionId!=undefined && user.get('register_type')>0)
        {
          userName=unionId
        }
       
       
       let re=await query.get(smsCode.id)
          
         
          try{
            await Parse.User.logIn(userName, re.get('code'));
           
            await this.updateUser(displayName,avartar)
            this.emitUser();
            this.controlService.backAfterLogin()
          }
          catch(err){
           
            this.emitUser();
            this.alertService.error(err);
          };
        
      }
      else{
        pass = '12345';

        try{
         await Parse.User.logIn(user.get('username'), pass)
            
         await this.updateUser(displayName,avartar)
          this.emitUser();
            this.controlService.backAfterLogin()

        }
       
       catch(err)
          {this.alertService.error(err);
            this.emitUser();};
      }
    }
    else{
     
      return false;
    }
}

async updateUser(displayName,avartar){
  let user=Parse.User.current()
  if(user.get('displayname')==undefined)
  {
    user.set('displayname',displayName)
    user.set('avartar',avartar)
    user.save()
  }
}

async signUp(userInfo:UserModel):Promise<any>{
 
  let user = new Parse.User();
  user.set('username', userInfo.userName);
  user.set('password', userInfo.password);
  try{
    let source=window.location.hostname.replace('www','')
    user.set('source', source);
  }
  catch(error){
    console.log(error)
  }
 
  if(userInfo.unionId!=undefined)
  {
    user.set('unionid', userInfo.unionId);
  }
  if(userInfo.openId!=undefined)
  {
    user.set('openid', userInfo.openId);
  }
  let smsCode = new Parse.Object('smsCode');
  let codeACL = new Parse.ACL();
  codeACL.setPublicReadAccess(true);
  codeACL.setPublicWriteAccess(false);
  smsCode.setACL(codeACL);
  smsCode.set('code', userInfo.password);
  smsCode = await smsCode.save();

  user.set('smsCode', smsCode);
  if(userInfo.mobile != undefined)
  {
    user.set('displayname', userInfo.userName);
    user.set('mobile', userInfo.mobile);
    user.set('region', userInfo.region)
    user.set('phoneVerified', true);
  }
  if(userInfo.email != undefined)
  {
    user.set('email', userInfo.email);
  }
  if(userInfo.displayname != undefined)
  {
    user.set('displayname', userInfo.displayname);
  }
  if(userInfo.avartar != undefined)
  {
    user.set('avartar', userInfo.avartar);
  }
  await user.signUp().catch(re=>{
    this.alertService.error(re);
  });
  const promoteCode= LocalMemory.getItemByKey('promote_code');
  LocalMemory.deleteItemByKey('promote_code');
  if(promoteCode!=undefined)
  {
    return this.promotionService.savePromotion({userId:user.id, promoteCode:promoteCode}).subscribe(re=>{
     
      this.emitUser();
      this.controlService.backAfterLogin();
    })
  }
  else
  {
    this.emitUser();
    this.controlService.backAfterLogin();
  }
 
}

async logout(){
    if (Parse.User.current()) {
        // do stuff with the user
        LocalMemory.clearStorage();
        await Parse.User.logOut();
    } 
    this.emitUser();
}

valid(){
  return Parse.User.current() != null;
}

getUser()
{
  return Parse.User.current();
}

getUserById(userId):Promise<Parse.User>{
  const userQuery = new Parse.Query(Parse.User);
  return userQuery.get(userId).then(us=>{
    return us;
  });
}

emitUser() {
    this.UserSubject.next(Parse.User.current());
  }

emitMember(member:Order){
  this.PermissionSubject.next(member);
}

 async checkExist(userName:string, unionId=undefined){
    if(unionId!=undefined)
    {
      let unionquery = new Parse.Query(Parse.User);
      unionquery.equalTo('unionid', unionId);
      unionquery.limit(1);
      unionquery.include(['_p_smsCode']);
      const user = await unionquery.limit(1).find();
    
      return user;
    }
    else
    {
      let query = new Parse.Query(Parse.User);
      query.equalTo('username', userName);
      query.limit(1);
      
      query.include(['_p_smsCode']);
      const user = await query.limit(1).find();
      return user;
    }
   
  }

  checkUnionId(unionId):Promise<any>{
    let unionquery = new Parse.Query(Parse.User);
      unionquery.equalTo('unionid', unionId);
      unionquery.limit(1);
      return unionquery.limit(1).find().then(re=>{
        return re;
      }  )
  }

  getAllUsers():Promise<Parse.User[]>{
    let query = new Parse.Query(Parse.User);
    return query.descending('createdAt').limit(100).find().then(re=>{
      return re;
    })
  }

  getUserCount():Promise<number>{
    let query = new Parse.Query(Parse.User);
    return query.count().then(re=>{
      return re;
    })
  }

  getUserByDate(fromDate=null, endDate=null):Promise<Map<string,number>>{

    let query = new Parse.Query(Parse.User);
    if(fromDate!=undefined && fromDate!=null)
    {
        query.greaterThanOrEqualTo('createdAt',fromDate);
    }
   
    if(endDate!=undefined && endDate!=null)
    {
        query.lessThanOrEqualTo('createdAt',endDate);
    }

    let userMaps=new Map<string,number>();
    return query.ascending('createdAt').limit(10000000).find().then(re=>{
      let i=0;
      for(let user of re)
      {
        let date = formatDate(user.get('createdAt'),'yyyy-MM-dd',this.locale);
        if(fromDate==null)
        {
            fromDate = new Date(date);
           
        }
        if(i==0)
        {
            let dateWin=this.generateWinLenth(fromDate, endDate);
            dateWin.forEach(el=>{
              userMaps.set(el,0);
            })
        }

        i+=1;
            if(userMaps.has(date))
            {
              userMaps.set(date, userMaps.get(date)+1);
            }
            else
            {
              userMaps.set(date, 1);
            }
            }
        return userMaps;
      });
  }

  generateWinLenth(fromDate, endDate){
    let win=[];
    let startDate =formatDate(fromDate,'yyyy-MM-dd',this.locale);
    win.push(startDate);
    let toDate=null;
    if(endDate==null)
    {
        toDate = formatDate(new Date(),'yyyy-MM-dd',this.locale);
    }
    else
    {
        toDate = formatDate(endDate,'yyyy-MM-dd',this.locale);
    }
    let new_date=startDate;
 
    let i=1;
  
    while(new_date !=toDate)
    {
        let nextDay=new Date(fromDate.getTime());

        nextDay.setDate(nextDay.getDate()+i);
       
        new_date = formatDate(nextDay,'yyyy-MM-dd',this.locale);
       
        win.push(new_date);
        i+=1;
    }

    return win;
}

  searchUser(keyword):Promise<Parse.User[]>{
    let query = new Parse.Query(Parse.User);
   
    let key ='\"'+keyword+'\"' ;
   
    query.fullText('displayname', key);
    return query.limit(100000).find().then(re=>{
      return re;
    })
  }

  getUserRole(){
    if(this.valid())
    {
      return Parse.User.current().get('role');
    }
    else
    {
      return 0;
    }
   
  }

  setUserRole(userId,role):Promise<boolean>
  {
    let query = new Parse.Query(Parse.User);
    return query.get(userId).then(user=>{
      user.set('role',role);
      return user.save().then(re=>{
        return true;
      }).catch(userEx=>{
        console.log(userEx);
        return false;
      })
    }).catch(ex=>{
      console.log(ex);
      return false;
    })

  }
}