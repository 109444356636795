import { MemberType } from "./question.types";


export class Company {
    id: string;
    title:string;
    name:string;
    logo:string;
    description:string;
    keywords:string;
    beian:string;
    wechat:string;
    gzh:string;
    company_name:string;
    company_address:string;
    email:string;
    domain:string;
    gongan:string;
    gongan_no:string;
    

    
    constructor(init?:Partial<Company>) {
        Object.assign(this, init);
    }

    static convertToModel(query){
        const product = new Company({id:query.id,
            name:query.get('name'),
            title:query.get('title'),
            logo:query.get('logo'),
            description:query.get('description'),
            keywords:query.get('keywords'),
            beian:query.get('beian'),
            wechat:query.get('wechat'),
            gzh:query.get('gzh'),
            company_name:query.get('company_name'),
            company_address:query.get('company_address'),
            email:query.get('email'),
            domain:query.get('domain'),
            gongan:query.get('gongan'),
            gongan_no:query.get('gongan_no')
           
        });
        return product;
      }

}