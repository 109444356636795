import { Order } from "./order.model";
import { MemberType } from "./question.types";


export class PlanProduct {
    id: string;
    type:number;
    name:string;
    discount:number;
    pay_info:string;
    valid:boolean;
    period:number;
    originalPrice:number;

    

    
    constructor(init?:Partial<PlanProduct>) {
        Object.assign(this, init);
    }

    static convertToModel(query){
        const product = new PlanProduct({id:query.id,
            type:query.get('type'),
            name:query.get('name'),
            discount:query.get('discount'),
            pay_info:query.get('pay_info'),
            period:query.get('period'),
            originalPrice:query.get('originalPrice')
           
        });
        return product;
      }
}