export enum Test{
    Verbal=1,
    Quant,
    Writing
}

export enum SectionType{
    TC=1,
    RC,
    Quant,
    Verbal,
    writng,
    translation,
    RCQuestion
}

export enum Verbal{
    TC=1,
    RC,
}

export enum TC{
    One=1,
    Two,
    Three,
    SE
}

export enum RC{
    One=1,
    Two,
    Three,
    Four
}

export enum Quant{
    Comparasion=1,
    OneOfMult,
    MulOfMult,
    Entry
}

export enum Generatation{
    Exist=1,
    Auto,
    Manual,
    rcSecond,
    tcSecond,
    adaptive,
    specific,
    user,
    testSet,
    newQuestion
}

export enum Level{
    undefined=0,
    Easy=1,
    Medium,
    Hard
}

export enum DisplayType{
    Single=1,
    Two,
    Multiple,
    Entry,
    Sentence
}

export enum MemberType{
    free=1,
    month,
    season
}

export enum QuestionLevel{
    one=1,
    two,
    three,
    four,
    five
}
export enum MainType{
    normal=1,
    new,
    high_frequency
}

export enum LenType{
    long=1,
    medium,
    short,
    logic
}
export enum MockType{
    verbal=1,
    quant,
    all
}

export enum ProductType{
    member=1,
    course,
    camp,
    plan,
    practice_set,
    package,
    mock_set
}

export enum PermissionType{
    high_frequency=1,
    member_discount,
    result_check,
    practice,
    member_try,
    mock_sets
}

export enum AuthType{
    free=0,
    try,
    paid,
    admin
}
export enum UserType{
    admin=1,
    assistant=2,
    partner=3,
    question_manager,
    student_user
}



