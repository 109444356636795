import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Order } from 'src/app/_models/gre/order.model';
import { LocalMemory } from './state.service';
import * as Parse from 'parse';
import { AuthType, ProductType } from 'src/app/_models/gre/question.types';
import { Camp } from 'src/app/_models/gre/camp.model';
import { CourseGroup } from 'src/app/_models/gre/course.group.model';
import { Product } from 'src/app/_models/gre/product.model';
import { PlanProduct } from 'src/app/_models/gre/product.plan.model';

@Injectable({
    providedIn: 'root'
  })
  export class OrderService{
    order = Parse.Object.extend("orders");
    orderObject= new this.order();

    courses = Parse.Object.extend("courseGroups");
    coursesObject= new this.courses();

    camp = Parse.Object.extend("camps");
    campObject= new this.camp();

    product = Parse.Object.extend("products");
    productObject= new this.product();

    planProduct = Parse.Object.extend("plans");
    planObject= new this.planProduct();
    constructor(){
    }

    getAllOrders(startDate,toDate, money):Promise<[Order[],Map<string, string>,Map<string, string>]>{
        const query = new Parse.Query(this.orderObject);
     
        query.greaterThanOrEqualTo('updatedAt', startDate);
       
        if(toDate!=null && toDate !=undefined)
        {
          query.lessThanOrEqualTo('updatedAt', toDate);
        }

        query.notEqualTo('type',ProductType.camp);

        if(money>0)
        {
          query.greaterThan('amount',0);
          query.equalTo('paid',true);
        }
        else if(money==0)
        {
          query.equalTo('amount',0);
          query.equalTo('paid',true);
        }
        else{
          query.equalTo('paid',false);
        }
       
        const userQuery = new Parse.Query(Parse.User);
        let users=new Map<string, string>();
        userQuery.matchesKeyInQuery('objectId','user',query);

        userQuery.findAll().then(re=>{
            if(re.length>0)
            {
              for(let u of re)
              {
                if(!users.has(u.id))
                {
                  users.set(u.id, u.get('displayname'))
                }
              }
            }
        }).catch(err=>{
 
         
          return undefined;
        });;


            let course=new Map<string, string>();
            const courseQuery = new Parse.Query(this.coursesObject);
            courseQuery.matchesKeyInQuery('objectId','activity', query);

          courseQuery.findAll().then(re=>{
            for(let co of re)
            {
              let cour = CourseGroup.convertToModel(co);
              if(!course.has(cour.id))
              {
                course.set(cour.id, cour.name);
              }
            }
          }).catch(err=>{
 
            
            return undefined;
          });
     
      
          const campQuery = new Parse.Query(this.campObject);
          campQuery.matchesKeyInQuery('objectId','activity', query);

          campQuery.findAll().then(re=>{
           
            for(let co of re)
            {
              let cour = Camp.convertToModel(co);
              if(!course.has(cour.id))
              {
                course.set(cour.id, cour.title);
              }
            }
          }).catch(err=>{
 
           
            return undefined;
          });  

          const memberQuery = new Parse.Query(this.productObject);
          memberQuery.matchesKeyInQuery('objectId','activity', query);
         memberQuery.findAll().then((results)=>{
            if(results.length>0)
            {
                for (let re of results)
                {
                    let cour = Product.convertToModel(re);
                    if(!course.has(cour.id))
                    {
                        course.set(cour.id, cour.name);
                    }
                }
                
            }
           
        }).catch(err=>{
 
         
          return undefined;
        });;

        const planQuery = new Parse.Query(this.planObject);
        planQuery.matchesKeyInQuery('objectId','activity', query);
        planQuery.findAll().then((results)=>{
          if(results.length>0)
          {
              for (let re of results)
              {
                  let cour = PlanProduct.convertToModel(re);
                  if(!course.has(cour.id))
                  {
                      course.set(cour.id, cour.name);
                  }
              }
              
          }
         
      }).catch(err=>{
 
      
        return undefined;
      });;

        query.notContainedIn('user',['NytxeY03Je']);
        query.descending('paid');
        query.addDescending('updatedAt');
        return query.limit(10000).find().then((results)=>{
            let orders:Order[]=[];
            if(results.length>0)
            {
               
                for(let rsult of results)
                {
                    const order = Order.convertToModel(rsult);
                    orders.push(order);
                }
               
                
               
            }
           return [orders,users, course];
        }).catch(err=>{
 
         
          return undefined;
        });;
    }

    getCoursesByType(user, type):Promise<string[]>{

        const query = new Parse.Query(this.orderObject);
        query.equalTo('user', user);
        query.equalTo('paid',true);
        if(type!=undefined)
        {
          query.equalTo('type', type);
        }

       
        return query.ascending('updatedAt').find().then((re)=>{
           let activIds=[];
          if(re.length>0)
          {
           
            for(let result of re)
            {
              const finalOrder = Order.convertToModel(result);
              if(!finalOrder.expired)
              {
                activIds.push(finalOrder.activity)
              }
              
            }
          }
          return activIds;
          
      });
    }

    getOrdersByType(user, type):Promise<string[]>{

      const query = new Parse.Query(this.orderObject);
      query.equalTo('user', user);
      query.equalTo('paid',true);
      if(type!=undefined)
      {
        query.equalTo('type', type);
      }

     
      return query.ascending('updatedAt').find().then((re)=>{
         let activIds=[];
        if(re.length>0)
        {
         
          for(let result of re)
          {
            const finalOrder = Order.convertToModel(result);
            if(!finalOrder.expired)
            {
              activIds.push(finalOrder.product)
            }
            
          }
        }
        return activIds;
        
    });
  }

  getAuth(user):Promise<Order>{
    const query = new Parse.Query(this.orderObject);
    query.equalTo('user', user);
    query.equalTo('paid',true);

    const productQuery=new Parse.Query(this.productObject);
    productQuery.matchesKeyInQuery('objectId','activity',query)    

    
    return query.ascending('updatedAt').find().then((re)=>{
      let orders=new Map<string, Order[]>();
       
        if(re.length>0)
        {
          for(let result of re)
          {
            const finalOrder = Order.convertToModel(result);
         
            if(orders.has(finalOrder.activity))
            {
              orders.get(finalOrder.activity).push(finalOrder)
            }
            else
            {
              orders.set(finalOrder.activity,[finalOrder])
            }
          }
         
          let maxAuth=undefined
          for(let k of orders.keys())
          {
            let auth= this.calculateValid(orders.get(k));
            if(auth.expired)
            {
              continue;
            }

            if(maxAuth==undefined){
              maxAuth=auth
              continue
            }

            if(auth.end_date>maxAuth.end_date)
            {
              maxAuth=auth
            }
          }
          if(maxAuth!=undefined)
          {
            maxAuth.permission_identity=AuthType.paid;
          }
         
          return maxAuth
          
        }
        else
        {
            return undefined;
        }
        
    });



  }

    getCurrentAuth(user:string,type:ProductType=undefined):Promise<Order>{
        const query = new Parse.Query(this.orderObject);
        query.equalTo('user', user);
        query.equalTo('paid',true);
        if(type!=undefined)
        {
          query.equalTo('type', type);
        }
        
        let orders:Order[]=[];
        return query.ascending('updatedAt').find().then((re)=>{
           
            if(re.length>0)
            {
             
              for(let result of re)
              {
                const finalOrder = Order.convertToModel(result);
                orders.push(finalOrder);
              }
             
              return this.calculateValid(orders);
            }
            else
            {
                return undefined;
            }
            
        });
    }

    calculateValid(orders:Order[]):Order
    {
      let i=0;
      let extra=0;
      let finalOrder=undefined;
      let start_date=undefined;
      for(let i of Array<number>(orders.length).keys())
      {
          if(start_date==undefined)
          {
            start_date = orders[i].start_date;
            
          }
          let new_date = new Date(start_date.getTime())
        
          let validDate = new Date(new_date.setMonth(new_date.getMonth()+orders[i].period+extra));
         

          if(orders.length==1)
          { 
              finalOrder = orders[i];
              finalOrder.end_date=validDate;

              finalOrder.expired=validDate<new Date();
              return finalOrder;
          }
          if(i<=orders.length-2)
          {
            if(validDate>= orders[i+1].start_date)
            {
              extra +=orders[i].period;
            }
            else
            {
              extra=0;
              start_date=undefined;
             
            }
          }
          if(i==orders.length-1)
          {
            finalOrder=orders[i];
            finalOrder.start_date=start_date;
            finalOrder.end_date = validDate;
            finalOrder.expired=validDate<new Date();
          
            return finalOrder;
          }
      }
    }

    getCount(activity):Promise<number>{
        const query = new Parse.Query(this.orderObject);
        query.equalTo('activity', activity);
        query.equalTo('paid', true);
        return query.count().then(re=>{
            return re;
        })
    }

    getUsersByProduct(product):Promise<[Map<string, Order[]>,Map<string, string>]>{
      const query = new Parse.Query(this.orderObject);
        query.equalTo('activity', product);
        query.equalTo('paid', true);
        query.descending('updatedAt');

        const userQuery = new Parse.Query(Parse.User);
        let users=new Map<string, string>();
        userQuery.matchesKeyInQuery('objectId','user',query);

        userQuery.findAll().then(re=>{
            if(re.length>0)
            {
              for(let u of re)
              {
                if(!users.has(u.id))
                {
                  users.set(u.id, u.get('displayname'))
                }
              }
            }
        });

        let orders=new Map<string, Order[]>();
        return query.limit(100000).find().then(re=>{
            for(let r of re){
              let order = Order.convertToModel(r);
              if(orders.has(order.user))
              {
                orders.get(order.user).push(order);
              }
              else
              {
                orders.set(order.user, [order])
              }
            }

            return [orders,users];
        })
    }

    getPermissionByUserAndActivity(user, activity,valid_len):Promise<boolean>{
        const query = new Parse.Query(this.orderObject);
        query.equalTo('user', user);
        query.equalTo('activity', activity);
        query.equalTo('paid', true);
        query.descending('updatedAt');
        if(valid_len==undefined)
        {
            return query.count().then(re=>{
                return re>0;
            })
        }
        else
        {
           return query.find().then(re=>{
              
                if(re.length>0)
                {
                   let order= Order.convertToModel(re[0]);
                   if(order.period==0 || order.period==undefined)
                   {
                       return true;
                   }
                   const expired=  new Date(order.start_date.setMonth(order.start_date.getMonth()+order.period)) >= new Date()
                  
                   return expired;
                }
                return false;
            })
        }
    }

    getPermissions(user:string):Promise<Order[]>{
      const query = new Parse.Query(this.orderObject);
      query.equalTo('user', user);
      query.equalTo('paid', true);
      query.descending('updatedAt');
      let orders=[];
      return query.find().then(re=>{
              
        for(let or of re)
        {
           let order= Order.convertToModel(or);
           if(order.period==0 || order.period==undefined)
           {
               orders.push(order);

           }
           else
           {
              const expired=  new Date(order.start_date.setMonth(order.start_date.getMonth()+order.period)) >= new Date()
              if(!expired)
              {
                orders.push(order);
              }
           }
        }
        return orders;
      })
    }

  }