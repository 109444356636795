import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LocalMemory } from './state.service';
import {UserPromotion} from 'src/app/_models/gre/user.promote.model';
import * as Parse from 'parse';
import { Order } from 'src/app/_models/gre/order.model';
import { Product } from 'src/app/_models/gre/product.model';
import { ProductType } from 'src/app/_models/gre/question.types';
import { OrderService } from './order.service';
import { WebsocketService } from './websocket.service';
import { PromotionNotifyService } from '../promotion.notify.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AlertService } from '../alert.service';
import { CommonService } from 'src/app/_models/common';
@Injectable({
    providedIn: 'root'
  })
  export class UserPromotionService{
    promotion = Parse.Object.extend("promotions");
    promotionObject= new this.promotion();
    product = Parse.Object.extend("products");
    productObject= new this.product();

    constructor(private orderService:OrderService,
      private alertService:AlertService,
      private http: HttpClient
     ){}
   
     savePromotion(group:UserPromotion){
  
      let url = CommonService.getApiUrl()+'/promote';  
      // let url = 'http://localhost:5000/promote';  
      return this.http.post(url, JSON.stringify(group)).pipe(map(result=>{
         const status = result['status'];
         if (status== 'Failed')
         {
           this.alertService.error(result['msg']);
           return false;
         }
         else if(status=='OK')
         {
           this.alertService.info('恭喜你，成功助力你的朋友！',{autoClose:true});
           const updated = result['updated'];
         
           return updated;
          
         }
         
      }));
     }

  getPromotionProduct():Promise<Product>{
    const query = new Parse.Query(this.productObject);
    query.equalTo('promotion', true);
    return query.limit(1).find().then((results)=>{
       
        if(results.length>0)
        {
            let product = Product.convertToModel(results[0]);
            return product; 
        }
        return undefined;
    });
}


  

  }