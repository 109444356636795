import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as Parse from 'parse';
import { ViewPermission } from 'src/app/_models/gre/view.permission.model';
import { AuthType, PermissionType } from 'src/app/_models/gre/question.types';
import { Order } from 'src/app/_models/gre/order.model';
@Injectable({
    providedIn: 'root'
  })
  export class ViewPermissionService{
    permission = Parse.Object.extend("viewPermissions");
    permissionObject= new this.permission();
   
    getPermission(user:string, permission):Promise<boolean>{
        const query = new Parse.Query(this.permissionObject);
        query.equalTo('user', user);
        query.equalTo('permission', permission);
        query.equalTo('valid',true)
        
        return query.count().then((results)=>{
          
           return results>0;
        });
    }

    getMemberTryPermission(user:string):Promise<Order>{
      const query = new Parse.Query(this.permissionObject);
        query.equalTo('user', user);
        query.equalTo('permission',  PermissionType.high_frequency);
        query.equalTo('valid', true);
        
        return query.descending('createdAt').limit(1).find().then((results)=>{
          if(results.length>0)
          {
            for(let r of results)
            {
              let perm = ViewPermission.convertToModel(r);

              let new_date = new Date(perm.date.getTime())
              let days=7;
              if(perm.valid_days!=undefined)
              {
                days=perm.valid_days
              }
              let validDate = new Date(new_date.setDate(new_date.getDate()+days));
              let order = new Order()
              order.end_date=validDate;
              order.expired = validDate<new Date();
              order.permission_identity=AuthType.paid;
              
              return order;
            }
          }
          return undefined; 
        });
    }

    convertToOrder(perm){
      let new_date = new Date(perm.date.getTime())
              let days=7;
              if(perm.valid_days!=undefined)
              {
                days=perm.valid_days
              }
              let validDate = new Date(new_date.setDate(new_date.getDate()+days));
              let order = new Order()
              order.end_date=validDate;
              order.expired = validDate<new Date();
              
              return order;
    }

    getPermissions(user:string):Promise<any[]>{
      const query = new Parse.Query(this.permissionObject);
        query.equalTo('user', user);
        return query.find().then((results)=>{
        
           let permissions=[];
           for(let re of results){
            let mo = ViewPermission.convertToModel(re);
            permissions.push(mo);
           }

           return permissions;
        });
    }

    setPermission(user:string, permission:PermissionType,valid_days=undefined){
      const recordObj =this.permission;
      var record = new recordObj();   
      record.set("user", user);
      record.set("permission", permission);
      record.set("valid", true);
      if (valid_days!=undefined)
      {
        record.set("valid_days", valid_days);
      }
      
      return record.save().then((result)=>{
          return ViewPermission.convertToModel(result);
      });
    }

    disablePermission(user:string, permission:PermissionType,valid=false){
      const query = new Parse.Query(this.permissionObject);
        query.equalTo('user', user);
        query.equalTo('permission', permission);
        
        return query.find().then((results)=>{
           for(let re of results){
            if(valid!=re.get('valid'))
            {
              re.set('valid',valid)
              re.save()
            }
            
           }
        });
    }
  }
