

 <div>
      <alert></alert>   
     
      <div>
          
      <router-outlet></router-outlet>          
     </div>

</div>



