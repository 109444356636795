import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LocalMemory } from './state.service';

@Injectable({
    providedIn: 'root'
  })
  export class RouteService{
   topNavKey:string='firstNav';
   secondNavKey:string = 'greNav';
   mainNavKey:string='maintype_nav';
   studyNav:string='study_nav';
   errorNav:string='mine_error_nav';
   admin_nav:string='admin_nav';
   typeNavKey:string='typeNav';
   filterNavKey:string='filterNav';

    practice_mode='practice_mode';
    specificKey='specificItem'

    savePracticeMode(value)
    {
        LocalMemory.saveItem(this.practice_mode, value);
    }

    getPracticeMode(){
    let firstNav = LocalMemory.getItemByKey(this.practice_mode);
    if(firstNav == undefined)
    {
        firstNav = 2;
    }

    return firstNav;
    }

   saveAdminNav(id){
    LocalMemory.saveItem(this.admin_nav, id);
   }

   setSpecificItem(value)
   {
    LocalMemory.saveItem(this.specificKey,value)
   }

   getSpecificItem()
   {
    return LocalMemory.getItemByKey(this.specificKey)
   }

   getAdminNav(){
    let firstNav = LocalMemory.getItemByKey(this.admin_nav);
    if(firstNav == undefined)
    {
      firstNav = 0;
    }

    return firstNav;
   }

   saveErrorNav(id){
    LocalMemory.saveItem(this.errorNav, id);
   }
   getErrorNav(){
    let firstNav = LocalMemory.getItemByKey(this.errorNav);
    if(firstNav == undefined)
    {
      firstNav = 0;
    }

    return firstNav;
   }
   getFirstNav(){
    let firstNav = LocalMemory.getItemByKey(this.topNavKey);
    if(firstNav == undefined)
    {
      firstNav = 1;
    }

    return firstNav;
   }

   saveFirstNav(id){
       LocalMemory.saveItem(this.topNavKey, id);
   }

   saveStudyNav(id)
   {
    LocalMemory.saveItem(this.studyNav, id);
   }

   saveFilterNav(key, value){
    LocalMemory.saveItem(key,value)
   }

   getFilterNav(key){
  
    return LocalMemory.getItemByKey(key)
   }

   getStudyNav()
   {
    let secondNav = LocalMemory.getItemByKey(this.studyNav);
    if(secondNav == undefined)
    {
        secondNav = 1;
    }

    return secondNav;
   }

   saveGreNav(parent,id){
       LocalMemory.saveItem(parent, id);
   }

   saveMainTypeNav(id){
    LocalMemory.saveItem(this.mainNavKey, id);
   }

   saveTypeNav(id){
       LocalMemory.saveItem(this.typeNavKey,id)
   }

   getTypeNav(){
   
       let typeNave=LocalMemory.getItemByKey(this.typeNavKey);
       return typeNave;
   }
   getGreNav(){
    let secondNav = LocalMemory.getItemByKey(this.secondNavKey);

    return secondNav;
   }

   getMainNav(){
    let mainNav = LocalMemory.getItemByKey(this.mainNavKey);
    return mainNav;
   }
   
   back(){
       let firstNav = this.getFirstNav();
       
       if(LocalMemory.getItemByKey('member') != undefined)
       {
           return '/main/order';
       }

       if(firstNav == 1)
       {
           return '/main'
       }
       else if(firstNav == 2)
       {
           return '/main/list';
       }
       else if(firstNav == 3){
        return '/mock';
       }
   }
  }