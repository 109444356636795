import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class CommonService {
    static step:number=5;
    static GenerationTypes=['','模考','','阅读','填空','测试套题','专项练习','','测试套题'];
    static SectionTypes=['填空','阅读','数学'];
    static TCSecondTypes=['一空题','二空题','三空题','六选二'];
    static RCSecondTypes=['长文章','短文章','','逻辑'];

    static TCCauses=['单词不认识','句子没读懂','逻辑找错','粗心','其他']
    static RCCauses=['单词不认识','句子没读懂','文章没理解','题目没看懂','选项没看懂','没掌握做题方法','粗心','其他']
    static QuantCauses=['知识点没掌握','题目没看懂','计算错误','粗心','其他']
    static Levels=['Easy','Medium','Hard']

    static getApiUrl(){
        if(environment.testType=="kaoyan"){
            return environment.kaoyan.apiUrl;
        }
        else if(environment.testType=='GRE')
        {
            return environment.GRE.apiUrl;
        }
    }

    static getHomeUrl(){
        if(environment.testType=="kaoyan"){
            return environment.kaoyan.home;
        }
        else if(environment.testType=='GRE')
        {
            return environment.GRE.home;
        }
    }

    static getWechatBackUrl(){
        if(environment.testType=="kaoyan"){
            return environment.kaoyan.wechatbakurl;
        }
        else if(environment.testType=='GRE')
        {
            return environment.GRE.wechatbakurl;
        }
    }

    static getCatGre(){
        return environment.GRE.catBackUrl;
    }

    static getServiceUrl(){
        if(environment.testType=="kaoyan"){
            return environment.kaoyan.serviceUrl;
        }
        else if(environment.testType=='GRE')
        {
            return environment.GRE.serviceUrl;
        }
    }

    static getAppName(){
        if(environment.testType=="kaoyan"){
            return environment.kaoyan.name;
        }
        else if(environment.testType=='GRE')
        {
            return environment.GRE.name;
        }
    }
    static getTestType(){
        return environment.testType;
    }

}