export const environment = {
  production: true,
  versionCheckURL : 'version.json',
  wsEndpoint:'http://139.196.76.6:5005/',
  testType:'GRE',
  kaoyan:{
    home:'https://kaoyan.mingxiaolu.cn',
    apiUrl:'https://yanapi.mingxiaolu.cn',
    serviceUrl:'https://yanservice.mingxiaolu.cn',
    name:'考研',
    wechatbakurl:'https://kaoyan.mingxiaolu.cn'
  },
  GRE:{
    home:'https://kaoyan.mingxiaolu.cn',
    apiUrl:'https://api.mingxiaolu.cn',
    serviceUrl:'https://service.mingxiaolu.cn',
    name:'GRE',
    wechatbakurl:'https://mingxiaolu.cn',
    catBackUrl:'https://catgre.com'
  }
  // parse: {
  //   appId: 'A9bVJTT5fmH5zqP8CPqDK1cva5NfkBP6',
  //   javascriptKey: '96SFBryScCCydhqwjG8ZhnsKjUC1jB5g',
  //   serverURL: 'http://127.0.0.1:8080/parse',
    
  // }
};