import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';

import { AppRoutingModule }     from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CommonModule } from '@angular/common';




import { AlertComponent } from './_components';



import { HttpClientModule } from '@angular/common/http';

import{LoggedInGuard} from './_services/login.guard';
import {Nl2BrPipeModule} from 'nl2br-pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';





import { NgSelectModule } from '@ng-select/ng-select';




import { SharedModule } from './shared/shared.module';
import { PopupComponent } from './_components/popup/popup.component';




@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    CommonModule,
   
    NgSelectModule,
    HttpClientModule,
    Nl2BrPipeModule,
    NgbModule,   

    SharedModule,
   
    
  ],
  providers: [LoggedInGuard],
  declarations: [ AppComponent,
     AlertComponent,
     PopupComponent
    

   

       ],
  bootstrap: [ AppComponent ]
})
export class AppModule {}