import { Activity } from "src/app/_models/gre/activity.model";
import { Product } from "src/app/_models/gre/product.model";

export class LocalMemory{
      
    static replacer(key, value) {
      if(value instanceof Map) {
        return {
          dataType: 'Map',
          value: Array.from(value.entries()), // or with spread: value: [...value]
        };
      } else {
        return value;
      }
    }

    static reviver(key, value) {
      if(typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
          return new Map(value.value);
        }
      }
      return value;
    }

      static saveItem(key,data) {
        const serializedState = JSON.stringify(data, LocalMemory.replacer);
        localStorage.setItem(key,serializedState);
      }
      
      static getItemByKey (key) {
        try{
            const serializedState = localStorage.getItem(key);
            if(serializedState === null){ return undefined }
            return JSON.parse(serializedState, LocalMemory.reviver);
        }catch(err){
            return undefined
        }
      }
      
      static deleteItemByKey (key){ localStorage.setItem(key,null)}
      
      static emptyLocalStorage (reducerkeys) {
      
        try{
            if(undefined != reducerkeys && reducerkeys.length > 0){
                reducerkeys.forEach(key => {
                    localStorage.setItem(key,null);
                })
            }
        }catch(err){
            //console.log("ERROR===emptyLocalStorage==>>>")
        }
      }
      
      static clearStorage(){ localStorage.clear();}

      static saveMember(buy:boolean, product:Product, activity:Activity, types:number[]){

        LocalMemory.saveItem('member', true);
        LocalMemory.saveItem('product', product);
        LocalMemory.saveItem('productSecond', types);
        LocalMemory.saveItem('activity', activity);
      }

      static removeMember(){
  
        LocalMemory.deleteItemByKey('member');
        LocalMemory.deleteItemByKey('product');
        LocalMemory.deleteItemByKey('productSecond');
      }
}
  