import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Order } from 'src/app/_models/gre/order.model';
import { SectionType } from 'src/app/_models/gre/question.types';
import { LocalMemory } from './state.service';
import { Company } from 'src/app/_models/gre/company.model';

@Injectable({
    providedIn: 'root'
  })
  export class ControlService{
    private subject = new ReplaySubject<any>(1);
    private showExplanation = new ReplaySubject<any>(1);
    private searchSub = new ReplaySubject<string>(1);
    private loginModalSub = new ReplaySubject<boolean>(1);
    private finishLoadProblem = new Subject<any>();
    private navSubject = new ReplaySubject<SectionType>(1);
    private playerSubject = new Subject<string>();
    private clockSubject = new Subject();
    private memberSubject = new ReplaySubject<Order>(1);
    private promotionSubject = new ReplaySubject(1);
    private noteSubject = new Subject();
    private editSubject= new Subject();
    private viewPermissionSubject=new ReplaySubject(1);
    private companySubject=new ReplaySubject<Company>(1);

   
    private courseSub=new ReplaySubject<Order>(1);


    constructor(private router:Router){

    }

    getCompanySub(){
      return this.companySubject;
    }

    setCompany(company){
      this.companySubject.next(company)
    }

    getCourseSub(){
      return this.courseSub
    }

    checkCourseSub(cousrses){
      this.courseSub.next(cousrses);
    }


    getViewPermissionSubject(){
      return this.viewPermissionSubject;
    }

    checkPermission(permission,allowed:boolean){
      this.viewPermissionSubject.next({permission:permission,allowed:allowed});
    }

    getNoteSubject(){
      return this.noteSubject;
    }

    getEditSubject(){
      return this.editSubject;
    }

    startEdit(data)
    {
      return this.editSubject.next(data);
    }

    startNote(){
      this.noteSubject.next();
    }
    updatePromotion(){
      this.promotionSubject.next();
    }
    getPromotionSubject(){
      return this.promotionSubject;
    }
    getMemberSubject():Observable<any>{
      return this.memberSubject;
    }
    subjectSwitch():Observable<any>{
      return this.navSubject;
    }

    getClockSubject():Observable<any>{
      return this.clockSubject;
    }

    getPlayerSubject():Observable<any>{
      return this.playerSubject;
    }

    switchSubject(type:SectionType){
      this.navSubject.next(type);
    }
    
    problemSwitch():Observable<any>{
        return this.subject;
    }

    finishedProblemSub():Observable<any>{
      return this.finishLoadProblem;
    }

    explanationSwitch():Observable<any>{
        return this.showExplanation;
    }

  //   needLogin():Observable<boolean>{
  //     return this.loginModalSub;
  // }

    getSearchSub():Observable<string>{
      return this.searchSub;
    }

    search(keyword:string){
      this.searchSub.next(keyword);
    }
    
    get(problemId:any){
      this.subject.next(problemId);
    }

    displayExplanation(info){
      this.showExplanation.next(info);
    }

    saveCurrentDomain(name){
      name=name.replace('www.','')
      LocalMemory.saveItem('domain',name)
    }

    saveCompany(domain,info)
    {
      LocalMemory.saveItem(domain,info)
    }

    getCompany(domain)
    {
      return LocalMemory.getItemByKey(domain)
    }



    getDomain(){
      let domain= LocalMemory.getItemByKey('domain')
      return domain
    }

    loginPopup(open){
      
      LocalMemory.saveItem('login_target',this.router.url);
      this.router.navigate(['/join']);
      return;
    }

    saveCurrent(){
      LocalMemory.saveItem('back_target',this.router.url);
    }
    
    backPrevious(){
      let prev = LocalMemory.getItemByKey('back_target')
    
      if(prev==undefined)
      {
        prev='/index';
      }
    
      this.router.navigate([prev]);
      return;
    }

    backAfterLogin(){
      let backUrl=LocalMemory.getItemByKey('login_target');
      if(backUrl==undefined ||  backUrl.includes('admin'))
      {
        backUrl='/index'
      }
        this.router.navigate([backUrl]);
    }

    saveOrderPrevious(){
      LocalMemory.saveItem('order_target',this.router.url);
    }

    backAfterOrder(){
      let backUrl=LocalMemory.getItemByKey('order_target');

      if(backUrl==undefined)
      {
        backUrl='/index'
      }
        this.router.navigate([backUrl]);
    }

    finishedNavProb(){
      this.finishLoadProblem.next(true);
    }

    playVideo(url){
      this.playerSubject.next(url)
    }

    clockCheck(){
      this.clockSubject.next();
    }

    member(order){
      this.memberSubject.next(order);
    }
  }