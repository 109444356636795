import { MemberType } from "./question.types";


export class CourseGroup {
    id: string;
    discount:number;
    index:number;
    targets:string[];
    price:number;
    watchable:boolean;
    time_description:string;
    name:string;
    valid_len:number;
    cover:string;
    description:string;
    valid:boolean;
    services:string[];
    userAccount:number;
    properties:string[];
    ad_video:string;
    ad_images:string;
    labels:string[];
    unit:string;
    level:number;
    help:string[];
    second_type:number;
    type:number;
    teachers:string[];
    mock_sets:string[];
    courses:string[];
    subCourse:boolean;
    

    
    constructor(init?:Partial<CourseGroup>) {
        Object.assign(this, init);
    }

    static convertToModel(query){
        const product = new CourseGroup({id:query.id,
            discount:query.get('discount'),
            index:query.get('index'),
            targets:query.get('targets'),
            price:query.get('price'),
            watchable:query.get('watchable'),
            time_description:query.get('time_description'),
            name:query.get('name'),
            valid_len:query.get('valid_len'),
            cover:query.get('cover'),
            description:query.get('description'),
            valid:query.get('valid'),
            services:query.get('services'),
            properties:query.get('properties'),
            ad_video:query.get('ad_video'),
            ad_images:query.get('ad_images'),
            unit:query.get('unit'),
            labels:query.get('labels'),
            level:query.get('level'),
            help:query.get('help'),
            second_type:query.get('second_type'),
            type:query.get('type'),
            teachers:query.get('teachers'),
            mock_sets:query.get('mock_sets'),
            courses:query.get('courses'),
            subCourse:query.get('subCourse')
        });
        return product;
      }

}