export class ViewPermission{
    user:string;
    type:number;
    date:Date;
    valid_days:number;
    valid:boolean;
    
    constructor(init?:Partial<ViewPermission>) {
        Object.assign(this, init);
    }

    static convertToModel(query){
        const subject = new ViewPermission({
            user:query.get('user'),
            type:query.get('permission'),
            date:query.get('createdAt'),
            valid_days:query.get('valid_days'),
            valid:query.get('valid')
       });
        
        return subject;
      }
}