import { Injectable } from '@angular/core';
import { Company } from 'src/app/_models/gre/company.model';
import * as Parse from 'parse';
@Injectable({
    providedIn: 'root'
  })
  export class CompanyService{
    company = Parse.Object.extend("company");
    companyObject= new this.company();

    get(domain):Promise<Company>
    {
       
        const query = new Parse.Query(this.companyObject);
        query.equalTo('domain', domain);
        return query.find().then((results)=>{           
            if(results.length>0)
            {
                let info =Company.convertToModel(results[0])
                return info;
            }

            return undefined
        })
    }
  }