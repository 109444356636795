import { MemberType } from "./question.types";


export class Camp {
    id: string;
    profit:string[];
    price:number;
    target:string[];
    index:number;
    finish:string[];
    title:string;
    content:string[];
    type:string;
    image:string;
    rule:string[];
    valid:boolean;
    short:string;
    userAcount:number;
    

    
    constructor(init?:Partial<Camp>) {
        Object.assign(this, init);
    }

    static convertToModel(query){
        const product = new Camp({id:query.id,
            profit:query.get('profit'),
            price:query.get('price'),
            target:query.get('target'),
            index:query.get('index'),
            finish:query.get('finish'),
            title:query.get('title'),
            content:query.get('content'),
            type:query.get('type'),
            image:query.get('image'),
            rule:query.get('rule'),
            valid:query.get('valid'),
            short:query.get('short')
        });
        return product;
      }

}