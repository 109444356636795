import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { SharedComponent } from './shared.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { BannerComponent } from './banner/banner.component';
import { FooterComponent } from './footer/footer.component';

import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';

import { RouterModule } from '@angular/router';
import { UserInfoComponent } from './banner/user-info/user-info.component';
import { CalendarComponent } from './calendar/calendar.component';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import { ProcessComponent } from './process/process.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { ErrorCauseComponent } from './error-cause/error-cause.component';
import { ErrorPieComponent } from './error-cause/error-pie/error-pie.component';
import { RecordPieComponent } from './error-cause/record-pie/record-pie.component';
import { ScoresComponent } from './scores/scores.component';
import { QuestionModifyComponent } from './question-modify/question-modify.component';
import { EditWinComponent } from './question-modify/edit-win/edit-win.component';
import { RcModifyComponent } from './question-modify/rc-modify/rc-modify.component';
import { TopAdComponent } from './top-ad/top-ad.component';
import { NewPracComponent } from './new-prac/new-prac.component';
import{JijingAdComponent} from './top-ad/jijing-ad/jijing-ad.component';
import { BackBannerComponent } from './back-banner/back-banner.component';
import { NewQuestionComponent } from './question-modify/new-question/new-question.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BlackSpinnerComponent } from './spinner/black-spinner/black-spinner.component';
import { PopupComponent } from './popup/popup.component'


@NgModule({
  declarations: [SharedComponent,
  BannerComponent,
  FooterComponent,
  CalendarComponent,

  SpinnerComponent,

  UserInfoComponent,
  ProcessComponent,
  ErrorCauseComponent,
  ErrorPieComponent,
  RecordPieComponent,
  ScoresComponent,
  QuestionModifyComponent,
  EditWinComponent,
  RcModifyComponent,
  TopAdComponent,
  NewPracComponent,
  JijingAdComponent,
  BackBannerComponent,
  NewQuestionComponent,
  DashboardComponent,
  BlackSpinnerComponent,
  PopupComponent

],
  imports: [
    CommonModule, ModalModule.forRoot(),
    FormsModule, ReactiveFormsModule,
    RouterModule,
    NgbDatepickerModule,
    HighchartsChartModule
  ],
  exports:[
    BannerComponent,
    FooterComponent,
    SpinnerComponent,
    CalendarComponent,
    ProcessComponent,
    ErrorCauseComponent,
    ErrorPieComponent,
    ModalModule,
    FormsModule, 
    ReactiveFormsModule,
    RouterModule,
    ScoresComponent,
    QuestionModifyComponent,
    EditWinComponent,
    TopAdComponent,
    NewPracComponent,
    JijingAdComponent,
    BackBannerComponent,
    DashboardComponent,
    BlackSpinnerComponent,
    PopupComponent
  
  ]
})
export class SharedModule { }
