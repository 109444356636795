import { Order } from "./order.model";
import { MemberType } from "./question.types";


export class Product {
    id: string;
    type:MemberType;
    name:string;
    price:number;
    description:string[];
    valid:boolean;
    recommendation:boolean;
    period:number;
    cover:string;
    discount:number;
    courses:string[];
    qa:string;
    detail:string;
    teacher:string[];
    identity:number;
    

    

    
    constructor(init?:Partial<Product>) {
        Object.assign(this, init);
    }

    static convertToModel(query){
        const product = new Product({id:query.id,
            type:query.get('type'),
            name:query.get('name'),
            price:query.get('price'),
            description:query.get('description'),
            cover:query.get('cover'),
            period:query.get('period'),
            qa:query.get('qa'),
            detail:query.get('detail'),
            discount:query.get('discount'),
            recommendation:query.get('recommendation'),
            courses:query.get('courses'),
            teacher:query.get('teacher'),
            identity:query.get('identity')
        });
        return product;
      }
}