import { MemberType, ProductType } from "./question.types";


export class Order {
    id: string;
    trade_id:string;
    amount:number;
    product:number;
    description:string;
    period:number;
    second_type:number[];
    user:string;
    ip:string;
    openId:string;
    date:Date;
    expired:boolean;
    start_date:Date;
    end_date:Date;
    activity:string;
    paid:boolean;
    type:ProductType;
    level:number;
    permission_identity:number;

    

    
    constructor(init?:Partial<Order>) {
        Object.assign(this, init);
    }

    static convertToModel(query){
        const order = new Order({
        id:query.id,
        trade_id:query.get('trade_id'),
        amount:query.get('amount'),
        user:query.get('user'),
        type:query.get('type'),
        product:query.get('product'),
        description:query.get('description'),
        period:query.get('period'),
        second_type:query.get('second_type'),
        activity:query.get('activity'),
        date:query.createdAt,
        paid:query.get('paid'),
        expired:query.get('expired'),
        level:query.get('level'),
        start_date:query.updatedAt
        });
        return order;
      }
}