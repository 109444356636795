import { Component, AfterViewInit, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import * as Parse from 'parse';
import { AlertService, UserService } from './_services';
import { ProductType, PermissionType, AuthType } from './_models/gre/question.types';
import { VersionCheckService } from './_services/admin/version-check.service';
import { environment } from 'src/environments/environment.prod';
import { PromotionNotifyService } from './_services/promotion.notify.service';
import { map } from 'rxjs/operators';
import { WebsocketService } from './_services/gre/websocket.service';
import { ControlService } from './_services/gre/control.service';
import { Subscription } from 'rxjs';
import { Router, Routes } from '@angular/router';
import { CommonService } from './_models/common';
import { ViewPermission } from './_models/gre/view.permission.model';
import { ViewPermissionService } from './_services/gre/view.permission.service';
import { OrderService } from './_services/gre/order.service';
import { Order } from './_models/gre/order.model';
import { Meta, Title } from '@angular/platform-browser';
import { CompanyService } from './_services/gre/company.service';
import { LocalMemory } from './_services/gre/state.service';
import { Console } from 'console';
declare var MathJax:any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']
})
export class AppComponent implements OnDestroy,OnInit,AfterViewInit {
  wechat:boolean = false;
  currentUser: Parse.User;
  qq:boolean=false;
  email:boolean=false;
  phone:boolean=false;
  userId:string;
  userSub:Subscription;
  isCat:boolean=false;
  content:string='';

  constructor( private userService:UserService,
    // private notifyService:PromotionNotifyService,
    
    private meta: Meta,
    private title:Title,
    private alertService:AlertService,
    private controlService:ControlService,
    private viewPermissionService:ViewPermissionService,
    private versionCheckService:VersionCheckService,
    private orderService:OrderService,
    private location: Location,
    private companyService:CompanyService,
    private elementRef: ElementRef,
    private router:Router) {
    
     
      this.versionCheckService.initVersionCheck(environment.versionCheckURL);
   

    Parse.initialize('zizhi');       
    (Parse as any).serverURL =CommonService.getServiceUrl();

    this.userService.emitUser();
  
     this.userSub= this.userService.UserSubject.subscribe((user)=>{
      this.currentUser = user;
      if(this.userService.valid())
      {
        this.userId=this.userService.getUser().id;
        console.log(this.userService.getUser().get('displayname').includes('peipei'))
        
        if(this.userService.getUser().get('displayname').includes('逐鹿'))
        {
        
          window.open('https://www.bilibili.com/video/BV1Va4y1n7HN/?spm_id_from=333.337.search-card.all.click','_self');
          return
        }
        this.getAuth();
      }
      else
      {
        this.controlService.member(undefined);  
      }

      this.setIndex();
    });
  }

  ngOnInit(): void {
   
  }

  ngAfterViewInit(): void {
   
  }

  

  setIndex(){
    let domain=window.location.hostname
    domain=domain.replace('www.','').trim()
   
    
    this.companyService.get(domain).then(re=>{
     
      this.controlService.setCompany(re)
      if(re!=undefined)
      {
        this.isCat=true;
        this.title.setTitle(re.title)
        this.meta.updateTag({ name: 'description', content: re.description });
        this.meta.updateTag({ name: 'keywords', content: re.keywords});
        
      }
      else
      {
        this.title.setTitle('名校录，录名校|GRE培训|GRE考试|GRE在线课程|GRE网课|GRE机经真题|名校录-GRE|宁波培栋教育科技有限公司')
        
      }
    })
   
  }

  getAuth(){
    if(this.userService.getUserRole()==1)
    {
      let validDate = new Date(8640000000000000);
      let order = new Order()
      order.end_date=validDate;
      order.expired = false;
      order.permission_identity=AuthType.admin;
      this.controlService.member(order); 
      return; 
    }
    this.orderService.getAuth(this.userId).then(
      (res)=>{   
        if(res==undefined)
        {
          this.viewPermissionService.getMemberTryPermission(this.userId).then(re=>{
          
          this.controlService.member(re);  
          })
        }
        else
        {
            this.controlService.member(res);  
        }       
      }
    );
  }

  ngOnDestroy(): void {
    if(this.userSub!=undefined)
    {
      this.userSub.unsubscribe();
    }
  }


  signOut(){
    this.userService.logout();
  }

}

